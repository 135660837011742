import revive_payload_client_VflOnddwOJ from "/app/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.78.0_typescript@5.5.4_vite@4.5.3_xml2js@0.6.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pcWgKwDcEA from "/app/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.78.0_typescript@5.5.4_vite@4.5.3_xml2js@0.6.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_J6V858yzT5 from "/app/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.78.0_typescript@5.5.4_vite@4.5.3_xml2js@0.6.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_uI3CzBRya5 from "/app/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.78.0_typescript@5.5.4_vite@4.5.3_xml2js@0.6.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_1y15p0smM9 from "/app/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.78.0_typescript@5.5.4_vite@4.5.3_xml2js@0.6.2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ceeTi220vG from "/app/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.78.0_typescript@5.5.4_vite@4.5.3_xml2js@0.6.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_EEY7wCBCJy from "/app/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.78.0_typescript@5.5.4_vite@4.5.3_xml2js@0.6.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_7VLubPclkq from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_typescript@5.5.4_vue@3.5.2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_0lHNTBzRaw from "/app/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_sass@1.78.0_typescript@5.5.4_vite@4.5.3_xml2js@0.6.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import gtm_client_OzmBuHLRIb from "/app/plugins/gtm.client.ts";
import sentry_client_GoGQuZo4Et from "/app/plugins/sentry.client.js";
import vuetify_7h9QAQEssH from "/app/plugins/vuetify.ts";
export default [
  revive_payload_client_VflOnddwOJ,
  unhead_pcWgKwDcEA,
  router_J6V858yzT5,
  payload_client_uI3CzBRya5,
  navigation_repaint_client_1y15p0smM9,
  check_outdated_build_client_ceeTi220vG,
  chunk_reload_client_EEY7wCBCJy,
  plugin_vue3_7VLubPclkq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_0lHNTBzRaw,
  gtm_client_OzmBuHLRIb,
  sentry_client_GoGQuZo4Et,
  vuetify_7h9QAQEssH
]