import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as privacy_45policyOPxqa3IsSYMeta } from "/app/pages/privacy-policy.vue?macro=true";
import { default as indexWctlfRoF06Meta } from "/app/pages/quote/index.vue?macro=true";
import { default as terms_45conditionsRRu4oncQRtMeta } from "/app/pages/terms-conditions.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "quote",
    path: "/quote",
    meta: indexWctlfRoF06Meta || {},
    component: () => import("/app/pages/quote/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionsRRu4oncQRtMeta || {},
    component: () => import("/app/pages/terms-conditions.vue").then(m => m.default || m)
  }
]